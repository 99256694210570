import { classNames } from '../../styles/utils';
import { NavLink } from 'react-router-dom';

type SubNavProp = {
  name: string;
  description: string;
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
  id: number | string;
  shouldRenderIfNotAdnmin?: boolean;
  link?: string;
};

type VerticalStepsProps = {
  openTab: number | string;
  setOpenTab: (id: number | string) => void;
  subNavigation: SubNavProp[];
  children: any;
  title: string;
};
export default function VerticalSteps({
  openTab,
  setOpenTab,
  subNavigation,
  children,
  title,
}: VerticalStepsProps) {
  return (
    <div className="h-full flex bg-blue-gray-50 overflow-hidden">
      <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
        {/* Mobile top navigation */}

        <main className="flex-1 flex overflow-hidden">
          <div className="flex-1 flex flex-col">
            {/* Breadcrumb */}
            <nav
              aria-label="Breadcrumb"
              className="bg-white border-b border-blue-gray-200 xl:hidden">
              <div className="py-3 px-4 flex-col items-start w-full md:flex-row md:flex">
                {subNavigation.map((item) => (
                  <li key={item.name} className="flex">
                    <div
                      className="flex items-center"
                      onClick={(e: any) => {
                        e.preventDefault();
                        setOpenTab(item.id);
                      }}>
                      <item.icon
                        color={item.id === openTab ? '#f97315' : '#2d3643'}
                        className={classNames(
                          item.id === openTab
                            ? 'text-orange-400'
                            : 'text-sidebar',
                          'flex-shrink-0 -mt-0.5 h-5 w-5',
                        )}
                        aria-hidden="true"
                      />
                      <NavLink
                        exact
                        // activeClassName={
                        //   item.link ? 'bg-gray-100 border-r-0' : undefined
                        // }
                        to={item.link ? item.link : '#'}
                        className={classNames(
                          item.id === openTab && !item.link
                            ? 'text-gray-900'
                            : 'text-gray-500',
                          'm-3 text-xs font-medium  hover:text-gray-500',
                        )}
                        aria-current={item.id === openTab ? 'page' : undefined}>
                        {item.name}
                      </NavLink>
                    </div>
                  </li>
                ))}
              </div>
            </nav>

            <div className="flex-1 flex h-full overflow-auto">
              {/* Secondary sidebar */}
              <nav
                aria-label="Sections"
                className="hidden flex-shrink-0 w-1/4 bg-white border-r-0 border-blue-gray-200 xl:flex xl:flex-col">
                <div className="flex-shrink-0 h-16 px-6 border-b border-blue-gray-200 flex items-center">
                  <p className="text-xl font-bold text-blue-gray-900">
                    {title}
                  </p>
                </div>
                <div className="flex-1 min-h-0 overflow-auto">
                  {subNavigation.map((item) => (
                    <NavLink
                      exact
                      to={item.link ? item.link : ''}
                      key={item.name}
                      onClick={
                        item.link
                          ? () => null
                          : (e) => {
                              e.preventDefault();
                              setOpenTab(item.id);
                            }
                      }
                      activeClassName={
                        item.link ? 'bg-gray-100 border-r-0' : undefined
                      }
                      className={classNames(
                        item.id === openTab && !item.link
                          ? 'bg-gray-100 border-r-0'
                          : 'hover:bg-blue-50 hover:bg-opacity-50',
                        'flex py-4  px-5 border-b border-blue-gray-200',
                      )}
                      aria-current={item.id === openTab ? 'page' : undefined}>
                      <item.icon
                        color={'#f97315'}
                        className="flex-shrink-0 -mt-0.5 h-6 w-6 text-orange-400"
                        aria-hidden="true"
                      />
                      <div className="ml-4 text-sm">
                        <p className="font-bold text-blue-gray-900 break-words">
                          {item.name}
                        </p>
                        <p className="mt-1 text-gray-500">{item.description}</p>
                      </div>
                    </NavLink>
                  ))}
                </div>
              </nav>

              {/* Main content */}
              <div className="flex-1 lg:bg-gray-100 border-l-0 lg:border lg:overflow-hidden md:pb-10">
                {children}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
