/* eslint-disable react-hooks/exhaustive-deps */
import { UserOwner, IDictionary, Query } from 'shared';
import { Field, FormikProps } from 'formik';
import { Form } from '../../../shared';
import { addMemberValidationSchema } from '../utils';
import { classNames } from '../../../../styles/utils';
import { useEffect } from 'react';
import { Spinner } from '../../../../styles/icons';

const filedClassName =
  'w-full p-2 mt-1 block border-gray-300 border rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500';
const labelClassName = 'block text-sm font-semibold text-blue-gray-900 mb-1';

const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || '';

const ChangeUsers = ({
  setOpen,
  handleAddMember,
  user = {
    email: '',
    role: '',
    id: null,
    prename: '',
    surname: '',
    salutation: '',
    user_owner_id: null,
    user_owner_type: '',
  },
  userOwner,
  dictionaryData,
  newMember,
  loggedInUserId,
}: {
  loggedInUserId: number;
  setOpen: any;
  handleAddMember: any;
  user?: any; // User type
  userOwner?: UserOwner[];
  dictionaryData?: IDictionary;
  newMember?: boolean;
}) => {
  // console.log('🚀 ~ newMember:', newMember, user);
  const { FetchUserDataById } = Query();
  const userId = user?.id || loggedInUserId;
  const {
    data: currentUserFromList,
    // isSuccess,
    // error: fetchUserErro,
    isLoading,
    refetch,
  } = FetchUserDataById({
    key: `user-${userId}`,
    id: userId,
    enabled: !!userId,
  });

  useEffect(() => {
    //we need to refetch the user data when the user id changes
    if (user?.id) refetch();
  }, [user?.id]);

  if (isLoading) return <Spinner />;
  if (!currentUserFromList && !newMember) return <>user not fount</>;
  // console.log('userOwner', currentUserFromList, user?.id);
  // const {
  //   email,
  //   role,
  //   prename,
  //   surname,
  //   user_owner_id,
  //   salutation,
  //   user_owner_type,
  //   user_dealer_assignments,
  // } = currentUserFromList;

  return (
    <div>
      <Form
        submitForm={handleAddMember}
        validationSchema={addMemberValidationSchema}
        initialValues={{
          salutation: newMember ? '' : currentUserFromList?.salutation,
          prename: newMember ? '' : currentUserFromList?.prename,
          surname: newMember ? '' : currentUserFromList?.surname,
          role: newMember ? '' : currentUserFromList?.role,
          user_owner_id: newMember
            ? ''
            : currentUserFromList?.user_owner_id
            ? `user_owner_id=${currentUserFromList?.user_owner_id}&user_owner_type=${currentUserFromList?.user_owner_type}`
            : '',
          email: newMember ? '' : currentUserFromList?.email,
        }}>
        {({
          errors,
          isSubmitting,
          dirty,
          values,
          submitCount,
          touched,
        }: FormikProps<any>) => {
          // console.log('values user ', values);
          return (
            <div className="mt-4">
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                <div className="sm:col-span-6">
                  <label htmlFor="branch_offices" className={labelClassName}>
                    Anrede
                  </label>
                  <Field
                    name="salutation"
                    component={Form.SelectField}
                    options={
                      dictionaryData
                        ? Object.values(
                            //@ts-ignore
                            dictionaryData.salutations,
                          ).map((item: any) => ({
                            value: item.name,
                            label: item.name,
                          }))
                        : { value: '', label: '' }
                    }
                  />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="prename" className={labelClassName}>
                    Vorname
                  </label>
                  <Field
                    className={filedClassName}
                    type="text"
                    name="prename"
                  />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="surname" className={labelClassName}>
                    Nachname
                  </label>
                  <Field
                    className={filedClassName}
                    type="text"
                    name="surname"
                  />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="email" className={labelClassName}>
                    E-Mail Adresse
                  </label>
                  <Field
                    className={filedClassName}
                    type="text"
                    name="email"
                    disabled={!newMember}
                  />
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="role" className={labelClassName}>
                    Standort
                  </label>

                  <Field
                    name="user_owner_id"
                    component={Form.SelectField}
                    options={
                      currentUserFromList?.user_dealer_assignments &&
                      currentUserFromList?.user_dealer_assignments.length > 0
                        ? currentUserFromList?.user_dealer_assignments
                            // .filter((user: UserOwner) => {
                            //   return user_owner_type === 'Dealer::BranchOffice'
                            //     ? user.user_owner_type ===
                            //         'Dealer::BranchOffice'
                            //     : user;
                            // })
                            .map((item: UserOwner) => ({
                              value: `user_owner_id=${item.user_owner_id}&user_owner_type=${item.user_owner_type}`,
                              label: `${item.user_owner_name}`,
                            }))
                        : { value: '', label: '' }
                    }
                  />
                  <label
                    className={`block text-xs font-medium text-gray-500 mt-2`}>
                    (Beim Standortwechsel erfolgt ein automatisches Logout.
                    Bitte melden Sie sich danach erneut an.)
                  </label>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="branch_offices" className={labelClassName}>
                    Rolle
                  </label>
                  <Field
                    isDisabled={
                      loggedInUserId === currentUserFromList?.id && !newMember
                    }
                    name="role"
                    value={currentUserFromList?.role}
                    component={Form.SelectField}
                    options={
                      dictionaryData
                        ? Object.values(
                            //@ts-ignore
                            dictionaryData.user_roles,
                          ).map((item: any) => ({
                            value: item.name,
                            label: capitalize(item.name),
                          }))
                        : { value: '', label: '' }
                    }
                  />
                </div>
              </div>
              {touched && Object.keys(errors).length > 0 && (
                <div className="pt-5">
                  <p className="text-red-400 text-sm font-semibold">
                    {Object.values(errors)[0]}
                  </p>
                </div>
              )}
              <div className={`pt-5 flex justify-end `}>
                <button
                  onClick={() => setOpen(false)}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  type="button">
                  Abbrechen
                </button>
                <button
                  disabled={!dirty || isSubmitting || submitCount > 4}
                  className={classNames(
                    dirty &&
                      Object.keys(errors).length === 0 &&
                      !isSubmitting &&
                      submitCount <= 4
                      ? 'bg-orange-500 hover:bg-gray-600-700 cursor-pointer'
                      : 'bg-gray-400 cursor-not-allowed',
                    'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500',
                  )}
                  type="submit">
                  Speichern
                </button>
              </div>
            </div>
          );
        }}
      </Form>
    </div>
  );
};

export default ChangeUsers;
